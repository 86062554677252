<!-- 字典数据 -->
<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
>
<script>
  import Layout from "@/views/layouts/main";
  import BaseHeader from "@/components/base-header";
  import {
    getDictSrc
  } from "@/api/common/dict.js";
  /**
   * Dashboard component
   */
  export default {
    components: {
      Layout,
      BaseHeader
    },
    data() {
      return {
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "数据字典",
            active: true
          }
        ],
        tableList: [],
        names: [],
        vals: {},
        obj: {},
        dictName: "",
        pageData: {
          pageNum: 1,
          pageSize: 20,
          total: 0
        },
      };
    },
    methods: {
     
      getDictDetail() {
        let tname = this.dictName
        getDictSrc({
          dictName: tname,
          ...this.pageData
        }).then(res => {
          if (res.status) {
            var item = res.data;
            this.names = Object.keys(item[0])
            this.vals = item
            this.pageData.total = res.total


          }
        })

      },
      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getDictDetail()
       
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val
        this.getDictDetail()
       
      },
      

      
    },
    mounted() {
      this.dictName = this.$route.query.dictName
      this.getDictDetail()
    },
   
    computed:{
      listHead(){
        return this.names.filter(k=>{
           return k!=='sid'
         })
      }
    }
  };
</script>

<template>
  <Layout>
    <BaseHeader :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList">
                <a href="javascript:history.go(-1);">
                  <el-button type="primary" size="small" class="ml-2 h30">返回上一页</el-button>
                </a>
              </div>
              <div class="d-flex">
                <div class="border-blue export-tab" 
                  @click="$exportModal().show({title:dictName , type: 'xlsx', module:dictName})"><i
                    class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title:dictName, type:'dbf', module:dictName})"><i
                    class="iconfont icon-data mr-2"></i>dbf</div>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table  light-table table-hover ">
                <thead class="thead-light">
                  <tr>
                    <th style="width:3%">序号</th>
                    <th v-for="obj in listHead" :key="obj.sid" >{{obj}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(value,index) in vals" :key="index">
                    <td>{{index+1+(pageData.pageNum-1)*pageData.pageSize }}</td>
                    <td v-for="obj in listHead" :key="obj.sid"  >{{value[obj]}}</td>
                  </tr>

                </tbody>
              </table>

            </div>
            <div class="float-right d-flex ">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>

            </div>
          </div>
        </div>
      </div>


    </div>

  </Layout>
</template>
